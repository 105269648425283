import {
  createFileRoute,
  Outlet,
  redirect,
  ScrollRestoration,
  ScrollRestorationOptions,
} from '@tanstack/react-router'
import { FC, Suspense, lazy } from 'react'
import { Flip, ToastContainer } from 'react-toastify'

import { Breadcrumbs } from '@/components/layout/breadcrumbs'
import { Navigation } from '@/components/layout/navigation/Navigation'

// eslint-disable-next-line unicorn/no-null
const NullComponent: FC = () => null
NullComponent.displayName = 'NullComponent'

/* v8 ignore next 10 */
const TanStackRouterDevtools =
  import.meta.env.PUBLIC_TANSTACK_ROUTER_DEV_TOOLS === 'true'
    ? lazy(
        async () =>
          await import('@tanstack/router-devtools').then(
            ({ TanStackRouterDevtools }) => ({
              default: TanStackRouterDevtools,
            })
          )
      )
    : NullComponent

/* v8 ignore next 10 */
const ReactQueryDevtoolsProduction =
  import.meta.env.PUBLIC_TANSTACK_QUERY_DEV_TOOLS === 'true'
    ? lazy(
        async () =>
          await import('@tanstack/react-query-devtools').then(
            ({ ReactQueryDevtools }) => ({
              default: ReactQueryDevtools,
            })
          )
      )
    : NullComponent

const getKey: ScrollRestorationOptions['getKey'] = (location) =>
  location.pathname

const PrivateLayout: FC = () => (
  <>
    <div
      className="flex h-screen grow flex-row overflow-hidden"
      data-testid="root-container"
    >
      <Navigation />
      <div className="flex w-full grow flex-col overflow-hidden">
        <Breadcrumbs />

        <ScrollRestoration getKey={getKey} />
        <Outlet />

        <ToastContainer
          draggable
          pauseOnHover
          position="bottom-right"
          transition={Flip}
        />
      </div>
    </div>

    <Suspense>
      <TanStackRouterDevtools position="bottom-right" />
    </Suspense>

    <Suspense>
      <ReactQueryDevtoolsProduction />
    </Suspense>
  </>
)
PrivateLayout.displayName = 'PrivateLayout'

export const Route = createFileRoute('/_private')({
  beforeLoad: async function privateRouteLoader({ context }) {
    const user = await context.getUser()

    if (!user) {
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw redirect({
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirect: location.href,
        },
        to: '/signin',
      })
    }
  },
  component: PrivateLayout,
})
