import { Link } from '@tanstack/react-router'
import { useCallback } from 'react'

import { TrashIcon } from '@/components/icons/TrashIcon'
import { Avatar } from '@/components/ui/avatar/Avatar'
import { Button } from '@/components/ui/button/Button'
import { EmptyState } from '@/features/index/components/EmptyState'
import { useDeleteNotifications } from '@/features/notification/hooks/useDeleteNotifications'
import { useViewerWithNotifications } from '@/features/notification/hooks/useViewerWithNotifications'
import { ChatMessage } from '@/features/task/components/chat/ChatMessage'
import { useUsers } from '@/features/task/hooks/useUsers'
import { formatMessageTimestamp } from '@/lib/utils'

export const NotificationList = ({
  hideNotificationSidebar,
}: {
  hideNotificationSidebar: () => void
}) => {
  const { viewer } = useViewerWithNotifications()
  const { users } = useUsers()
  const deleteNotificationsMutation = useDeleteNotifications()

  const handleDelete = useCallback(
    (ids: string[]) => {
      deleteNotificationsMutation.mutate(ids)
    },
    [deleteNotificationsMutation]
  )

  const getDeleteHandler = useCallback(
    (id: string) => () => {
      handleDelete([id])
    },
    [handleDelete]
  )

  return (
    <div className="flex flex-1 flex-col gap-3 overflow-auto p-12">
      {viewer.notifications.length === 0 ? (
        <EmptyState title="No updates" subtitle="You have no new updates." />
      ) : (
        viewer.notifications.map((notification, index) => {
          const readableTaskId = `TASK-${notification.task?.id.slice(-4)}`

          return (
            <div
              key={index}
              className="rounded-2xl border border-film-faint bg-mono-paper-translucent p-3 shadow-sm"
              data-testid={`viewer-notification-${index}`}
            >
              <div className="flex gap-2">
                <div>
                  <Avatar
                    src={notification.sourceMessage?.author.avatarUrl}
                    alt={notification.sourceMessage?.author.name}
                    className="size-8 min-w-8 rounded-full"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2 text-nowrap">
                    <p className="text-sm-regular text-mono-ink-strong">
                      {notification.sourceMessage?.author.name}
                      <span className="text-mono-ink-subtle">
                        {' mentioned you' +
                          `${notification.task?.id ? ' in' : ''} `}
                      </span>
                      {notification.task?.id && (
                        <Link
                          data-testid={`open-notification-task-${notification.task.id}`}
                          className="-ml-0.5 p-1 hover:rounded-sm hover:border-film-subtle hover:bg-film-normal focus:rounded-sm focus:bg-sky-50 focus:outline focus:outline-sky-300"
                          to={`/all-work/${notification.task.id}`}
                          onClick={hideNotificationSidebar}
                        >
                          {readableTaskId}
                        </Link>
                      )}
                    </p>
                    <span className="text-xs-regular text-film-strongest">
                      {formatMessageTimestamp(
                        notification.sourceMessage?.createdAt ??
                          notification.createdAt
                      )}
                    </span>
                  </div>
                  <p className="w-fit min-w-1 whitespace-pre-wrap rounded-lg border bg-film-subtle p-3">
                    {notification.sourceMessage ? (
                      <ChatMessage
                        message={notification.sourceMessage?.body.text ?? ''}
                        users={users}
                      />
                    ) : (
                      <span
                        className="text-film-strongest"
                        data-testid={`message-deleted`}
                      >
                        This message has been deleted
                      </span>
                    )}
                  </p>
                </div>
                <div className="ml-auto">
                  <Button
                    className="relative size-11 w-11 rounded-full"
                    size="sm"
                    data-testid={`delete-button-${notification.id}`}
                    onClick={getDeleteHandler(notification.id)}
                  >
                    <TrashIcon />
                  </Button>
                </div>
              </div>
            </div>
          )
        })
      )}
    </div>
  )
}

NotificationList.displayName = 'NotificationList'
