/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** Date with time (isoformat) */
  DateTime: { input: string; output: string }
  /** Represents NULL values */
  Void: { input: any; output: any }
}

/** Represents a distinct activity in the system. */
export type Activity = {
  __typename?: 'Activity'
  /** Unique identifier for the activity. */
  id: Scalars['ID']['output']
  /** A summary or brief description of this node's purpose. */
  summary: Scalars['String']['output']
}

/** Represents a cluster of activities and its associated flow in the system. */
export type ActivityFlowDataNode = {
  __typename?: 'ActivityFlowDataNode'
  /** A list of activities associated with this node. */
  activities: Array<Activity>
  /** The total number of activities in this node. */
  activityCount: Scalars['Int']['output']
  /** A list of nodes adjacent to this one in the flow. */
  adjacentNodes: Array<AdjacentActivityFlowDataNode>
  /** The id of the node. */
  nodeId: Scalars['Int']['output']
  /** The id of the parent node. */
  parentId?: Maybe<Scalars['Int']['output']>
  /** A summary or brief description of this node's purpose. */
  summary: Scalars['String']['output']
}

/** Represents a node that is adjacent to another in the activity flow. */
export type AdjacentActivityFlowDataNode = {
  __typename?: 'AdjacentActivityFlowDataNode'
  /** Unique identifier for the adjacent node. */
  nodeId: Scalars['Int']['output']
  /** The total number of traces going from the current to the adjacent node. */
  traceCount: Scalars['Int']['output']
}

export type ApplyTaskRefinementInput = {
  /** The id of the TaskRefinement to apply. */
  id: Scalars['ID']['input']
}

/**
 *
 * A change request, for now, only applicable to Tasks.
 *
 */
export type ChangeRequest = {
  __typename?: 'ChangeRequest'
  /** The change to be made to the Task. */
  change: TaskChange
}

export type CreateMessageInput = {
  /** The message body. */
  body: MessageBodyInput
  /** The id of the thread the message is attached to. */
  threadId: Scalars['ID']['input']
}

export type CreateTaskInput = {
  /** The id of an optional user to assign the task to. */
  assigneeId?: InputMaybe<Scalars['ID']['input']>
  /** An optional description of a task. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The id of an optional parent task. */
  parentTaskId?: InputMaybe<Scalars['ID']['input']>
  /** The title of the task. Can not be empty string. */
  title: Scalars['String']['input']
}

/** A chat message send by a user. */
export type Message = {
  __typename?: 'Message'
  /** The author who sent the message. */
  author: User
  /** The body of a message containing the message text. */
  body: MessageBody
  /** The date and time the message was created. */
  createdAt: Scalars['DateTime']['output']
  /** The automatically generated id of a message. Ordered from oldest to newwest. */
  id: Scalars['ID']['output']
  /** The task related to the message. */
  task?: Maybe<Task>
  /** The type of the message */
  type: MessageType
}

/** The body of a message. */
export type MessageBody = {
  __typename?: 'MessageBody'
  /** The text of a message body. */
  text: Scalars['String']['output']
}

/** Input type for message body. */
export type MessageBodyInput = {
  text: Scalars['String']['input']
}

export type MessageType =
  | 'MESSAGE'
  | 'TASK_CREATED'
  | 'TASK_STATUS_COMPLETED'
  | 'TASK_STATUS_OPENED'

export type Mutation = {
  __typename?: 'Mutation'
  /** Apply the updates of a TaskRefinement. */
  applyTaskRefinement: TaskRefinement
  /** Creates a new message. */
  createMessage: Message
  /** Creates a new task. */
  createTask: Task
  /** Deletes notifcations by id. */
  deleteNotifications?: Maybe<Scalars['Void']['output']>
  /** Deletes a task by id. */
  deleteTask?: Maybe<Scalars['Void']['output']>
  /** Moves subtasks to a new position in a subtask list. Returns the updated parent task of the subtasks. */
  orderSubtasks: Task
  /** Create a refinement of a Task. */
  refineTask: TaskRefinement
  runFunction: RunFunctionResponse
  /** Set task status by id. */
  setTaskStatus: Task
  /** Updates a task. */
  updateTask: Task
}

export type MutationApplyTaskRefinementArgs = {
  input: ApplyTaskRefinementInput
}

export type MutationCreateMessageArgs = {
  input: CreateMessageInput
}

export type MutationCreateTaskArgs = {
  input: CreateTaskInput
}

export type MutationDeleteNotificationsArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteTaskArgs = {
  id: Scalars['ID']['input']
}

export type MutationOrderSubtasksArgs = {
  input: OrderSubtasksInput
}

export type MutationRefineTaskArgs = {
  input: RefineTaskInput
}

export type MutationRunFunctionArgs = {
  input: RunFunctionInput
}

export type MutationSetTaskStatusArgs = {
  input: SetTaskStatusInput
}

export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput
}

/** A notification send to a user. */
export type Notification = {
  __typename?: 'Notification'
  /** The date and time the message was created. */
  createdAt: Scalars['DateTime']['output']
  /** The automatically generated id of a notification. Ordered from oldest to newest. */
  id: Scalars['ID']['output']
  /** The original message that triggered the notification. */
  sourceMessage?: Maybe<Message>
  /** The task associated with the notification. */
  task?: Maybe<Task>
}

export type OrderSubtasksInput = {
  /** The id of a distinct subtask to insert after. */
  insertAfterSubtaskWithId?: InputMaybe<Scalars['ID']['input']>
  /** The ids of the subtasks to move. */
  orderedSubtaskIds: Array<Scalars['ID']['input']>
}

export type PreviousTaskRefinementFeedbackInput = {
  /** The id of a previous TaskRefinement. */
  taskRefinementId: Scalars['ID']['input']
  /** The user feedback on the TaskRefinement. */
  userFeedback: Array<Scalars['String']['input']>
}

export type Query = {
  __typename?: 'Query'
  /** Retrieve activity flow data for a task. */
  getTaskActivityFlowData: Array<ActivityFlowDataNode>
  /** Retrieve a list of similar tasks. */
  similarTasks: Array<SimilarTaskResult>
  /** Retrieve a list of tasks. */
  tasks: Array<Task>
  /** Retrieve a list of users. */
  users: Array<User>
  /** Retrieve viewer data. */
  viewer: Viewer
}

export type QueryGetTaskActivityFlowDataArgs = {
  taskId: Scalars['ID']['input']
}

export type QuerySimilarTasksArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  minSimilarityScore?: InputMaybe<Scalars['Float']['input']>
  taskId: Scalars['ID']['input']
}

export type QueryTasksArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type RefineTaskInput = {
  /** An optional set of ids of Tasks to use as precedent for refining the Task. When omitted, the set of Tasks to be used as precedent will be determined automatically. */
  precedentTaskIds?: InputMaybe<Array<Scalars['ID']['input']>>
  /** Optional feedback for a previous TaskRefinement that should be considered when refining the task. */
  previousTaskRefinementFeedback?: InputMaybe<PreviousTaskRefinementFeedbackInput>
  /** The id of the task to refine. */
  taskId: Scalars['ID']['input']
}

export type RunFunctionInput = {
  query: Scalars['String']['input']
}

export type RunFunctionResponse = {
  __typename?: 'RunFunctionResponse'
  status: Scalars['String']['output']
}

export type SetTaskStatusInput = {
  /** The id of the task to update. */
  id: Scalars['ID']['input']
  /** The new task status to be set. */
  status: TaskStatus
}

/**
 *
 * Tasks that have been deemed similar along with their respective similarity score.
 *
 */
export type SimilarTaskResult = {
  __typename?: 'SimilarTaskResult'
  /** The similarity score of the task to the original task. */
  score: Scalars['Float']['output']
  /** The task associated with the similarity score. */
  task: Task
}

/**
 *
 * A task represents a unit of work that is to be completed.
 * Collections of tasks and their subtasks are used to track, plan, and structure work.
 *
 */
export type Task = {
  __typename?: 'Task'
  /** The user assigned to the task. */
  assignee?: Maybe<User>
  /** The id of the user assigned to the task. */
  assigneeId?: Maybe<Scalars['ID']['output']>
  /** The date and time the task was completed. */
  completedAt?: Maybe<Scalars['DateTime']['output']>
  /** The date and time the task was created. */
  createdAt: Scalars['DateTime']['output']
  /** Optional description of a task. */
  description?: Maybe<Scalars['String']['output']>
  /** The due date until the task should be completed. */
  dueAt?: Maybe<Scalars['DateTime']['output']>
  /** The id of the task. Automatically generated. Ordered from oldest to newest when sorted lexicographically. */
  id: Scalars['ID']['output']
  /** The parent task of the task. */
  parentTask?: Maybe<Task>
  /** The subtasks of the task. */
  subtasks: Array<Task>
  /** The thread associated with the task. */
  thread?: Maybe<Thread>
  /** The title of the task. */
  title: Scalars['String']['output']
}

/**
 *
 * A new Task or a change to be made to an existing Task.
 *
 */
export type TaskChange = {
  __typename?: 'TaskChange'
  /** An updated parent Task id. Pass `null` to orphan the Task. */
  parentTaskId?: Maybe<Scalars['ID']['output']>
  /** An updated title to be applied to the Task. */
  title?: Maybe<Scalars['String']['output']>
}

/**
 *
 * A proposed set of changes aimed to improve the execution of a Task.
 *
 */
export type TaskRefinement = {
  __typename?: 'TaskRefinement'
  /** The id of the TaskRefinement. */
  id: Scalars['ID']['output']
  /** The Tasks that were used as precedent to generate the refinement. */
  precedentTasks: Array<Task>
  /** The Task that should be refined. */
  task: Task
  /** The set of Change Requests that make up the refinement. */
  updates: Array<ChangeRequest>
}

export type TaskStatus = 'COMPLETED' | 'OPEN'

/**
 *
 * A thread represents a collection of messages.
 *
 */
export type Thread = {
  __typename?: 'Thread'
  /** The id of the thread. Automatically generated. */
  id: Scalars['ID']['output']
  /** The messages in the thread. */
  messages: Array<Message>
}

export type UpdateTaskInput = {
  /** The id of an optional user to assign the task to. */
  assigneeId?: InputMaybe<Scalars['ID']['input']>
  /** An optional updated description of a task. */
  description?: InputMaybe<Scalars['String']['input']>
  /** An optional due date of a task. Should use ISO string. */
  dueAt?: InputMaybe<Scalars['DateTime']['input']>
  /** The id of the task to update. */
  id: Scalars['ID']['input']
  /** The id of the parent task. */
  parentTaskId?: InputMaybe<Scalars['ID']['input']>
  /** An optional updated title of the task. Can not be empty string. */
  title?: InputMaybe<Scalars['String']['input']>
}

/**
 *
 * A user represents a person who can be assigned to tasks.
 *
 */
export type User = {
  __typename?: 'User'
  /** The url of the user's avatar. */
  avatarUrl?: Maybe<Scalars['String']['output']>
  /** The id of the user. */
  id: Scalars['ID']['output']
  /** The name of the user. */
  name: Scalars['String']['output']
}

/**
 *
 * A viewer represents the currently signed in user based on the access token.
 *
 */
export type Viewer = {
  __typename?: 'Viewer'
  /** The url of the user's avatar. */
  avatarUrl?: Maybe<Scalars['String']['output']>
  /** The id of the viewer. */
  id: Scalars['ID']['output']
  /** The name of the user. */
  name: Scalars['String']['output']
  /** The notifications sent to the viewer. */
  notifications: Array<Notification>
  /** The tasks a viewer is assigned to. */
  tasks: Array<Task>
}

export type ApplyTaskRefinementMutationVariables = Exact<{
  refinementId: Scalars['ID']['input']
}>

export type ApplyTaskRefinementMutation = {
  __typename?: 'Mutation'
  applyTaskRefinement: { __typename?: 'TaskRefinement'; id: string }
}

export type CreateMessageMutationVariables = Exact<{
  input: CreateMessageInput
}>

export type CreateMessageMutation = {
  __typename?: 'Mutation'
  createMessage: {
    __typename?: 'Message'
    id: string
    createdAt: string
    author: { __typename?: 'User'; id: string; name: string }
    body: { __typename?: 'MessageBody'; text: string }
  }
}

export type CreateTaskMutationVariables = Exact<{
  taskInput: CreateTaskInput
}>

export type CreateTaskMutation = {
  __typename?: 'Mutation'
  createTask: {
    __typename?: 'Task'
    id: string
    title: string
    createdAt: string
  }
}

export type DeleteTaskMutationVariables = Exact<{
  taskId: Scalars['ID']['input']
}>

export type DeleteTaskMutation = {
  __typename?: 'Mutation'
  deleteTask?: any | null
}

export type DeleteNotificationsMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type DeleteNotificationsMutation = {
  __typename?: 'Mutation'
  deleteNotifications?: any | null
}

export type FetchAllUsersQueryVariables = Exact<{ [key: string]: never }>

export type FetchAllUsersQuery = {
  __typename?: 'Query'
  users: Array<{
    __typename?: 'User'
    id: string
    name: string
    avatarUrl?: string | null
  }>
}

export type FetchAllTasksQueryVariables = Exact<{ [key: string]: never }>

export type FetchAllTasksQuery = {
  __typename?: 'Query'
  tasks: Array<{
    __typename?: 'Task'
    id: string
    title: string
    createdAt: string
    completedAt?: string | null
    dueAt?: string | null
    assignee?: {
      __typename?: 'User'
      id: string
      name: string
      avatarUrl?: string | null
    } | null
  }>
}

export type SimilarTasksQueryVariables = Exact<{
  taskId: Scalars['ID']['input']
  minSimilarityScore?: InputMaybe<Scalars['Float']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SimilarTasksQuery = {
  __typename?: 'Query'
  similarTasks: Array<{
    __typename?: 'SimilarTaskResult'
    task: {
      __typename?: 'Task'
      id: string
      title: string
      description?: string | null
      completedAt?: string | null
      assignee?: {
        __typename?: 'User'
        id: string
        name: string
        avatarUrl?: string | null
      } | null
    }
  }>
}

export type TaskMessagesQueryVariables = Exact<{
  taskId: Scalars['ID']['input']
}>

export type TaskMessagesQuery = {
  __typename?: 'Query'
  tasks: Array<{
    __typename?: 'Task'
    thread?: {
      __typename?: 'Thread'
      id: string
      messages: Array<{
        __typename?: 'Message'
        id: string
        createdAt: string
        type: MessageType
        author: {
          __typename?: 'User'
          id: string
          name: string
          avatarUrl?: string | null
        }
        body: { __typename?: 'MessageBody'; text: string }
        task?: {
          __typename?: 'Task'
          title: string
          description?: string | null
        } | null
      }>
    } | null
  }>
}

export type FetchTasksByIdQueryVariables = Exact<{
  taskIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type FetchTasksByIdQuery = {
  __typename?: 'Query'
  tasks: Array<{
    __typename?: 'Task'
    id: string
    title: string
    createdAt: string
    completedAt?: string | null
    dueAt?: string | null
    description?: string | null
    assigneeId?: string | null
    thread?: {
      __typename?: 'Thread'
      id: string
      messages: Array<{
        __typename?: 'Message'
        id: string
        createdAt: string
        author: {
          __typename?: 'User'
          id: string
          name: string
          avatarUrl?: string | null
        }
        body: { __typename?: 'MessageBody'; text: string }
      }>
    } | null
    subtasks: Array<{
      __typename?: 'Task'
      id: string
      title: string
      description?: string | null
      createdAt: string
      completedAt?: string | null
      assignee?: {
        __typename?: 'User'
        id: string
        name: string
        avatarUrl?: string | null
      } | null
    }>
    parentTask?: {
      __typename?: 'Task'
      id: string
      title: string
      completedAt?: string | null
      parentTask?: { __typename?: 'Task'; id: string } | null
    } | null
  }>
}

export type OrderSubtasksMutationVariables = Exact<{
  orderedSubtaskIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
  insertAfterSubtaskWithId?: InputMaybe<Scalars['ID']['input']>
}>

export type OrderSubtasksMutation = {
  __typename?: 'Mutation'
  orderSubtasks: {
    __typename?: 'Task'
    subtasks: Array<{ __typename?: 'Task'; id: string }>
  }
}

export type RefineTaskMutationVariables = Exact<{
  taskId: Scalars['ID']['input']
  precedentTaskIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >
  previousTaskRefinementFeedback?: InputMaybe<PreviousTaskRefinementFeedbackInput>
}>

export type RefineTaskMutation = {
  __typename?: 'Mutation'
  refineTask: {
    __typename?: 'TaskRefinement'
    id: string
    updates: Array<{
      __typename?: 'ChangeRequest'
      change: {
        __typename?: 'TaskChange'
        parentTaskId?: string | null
        title?: string | null
      }
    }>
    precedentTasks: Array<{
      __typename?: 'Task'
      id: string
      title: string
      completedAt?: string | null
      createdAt: string
      assignee?: {
        __typename?: 'User'
        name: string
        avatarUrl?: string | null
      } | null
    }>
  }
}

export type SetTaskStatusMutationVariables = Exact<{
  id: Scalars['ID']['input']
  status: TaskStatus
}>

export type SetTaskStatusMutation = {
  __typename?: 'Mutation'
  setTaskStatus: {
    __typename?: 'Task'
    id: string
    completedAt?: string | null
  }
}

export type UpdateTaskMutationVariables = Exact<{
  id: Scalars['ID']['input']
  title?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  assigneeId?: InputMaybe<Scalars['ID']['input']>
  dueAt?: InputMaybe<Scalars['DateTime']['input']>
  parentTaskId?: InputMaybe<Scalars['ID']['input']>
}>

export type UpdateTaskMutation = {
  __typename?: 'Mutation'
  updateTask: {
    __typename?: 'Task'
    id: string
    title: string
    description?: string | null
    assigneeId?: string | null
    dueAt?: string | null
  }
}

export type FetchViewerQueryVariables = Exact<{ [key: string]: never }>

export type FetchViewerQuery = {
  __typename?: 'Query'
  viewer: {
    __typename?: 'Viewer'
    id: string
    name: string
    avatarUrl?: string | null
  }
}

export type FetchViewerWithTasksQueryVariables = Exact<{ [key: string]: never }>

export type FetchViewerWithTasksQuery = {
  __typename?: 'Query'
  viewer: {
    __typename?: 'Viewer'
    id: string
    name: string
    avatarUrl?: string | null
    tasks: Array<{
      __typename?: 'Task'
      id: string
      title: string
      description?: string | null
      completedAt?: string | null
      parentTask?: { __typename?: 'Task'; id: string; title: string } | null
    }>
  }
}

export type FetchViewerWithNotificationsQueryVariables = Exact<{
  [key: string]: never
}>

export type FetchViewerWithNotificationsQuery = {
  __typename?: 'Query'
  viewer: {
    __typename?: 'Viewer'
    id: string
    name: string
    avatarUrl?: string | null
    notifications: Array<{
      __typename?: 'Notification'
      id: string
      createdAt: string
      sourceMessage?: {
        __typename?: 'Message'
        id: string
        createdAt: string
        author: {
          __typename?: 'User'
          id: string
          name: string
          avatarUrl?: string | null
        }
        body: { __typename?: 'MessageBody'; text: string }
      } | null
      task?: { __typename?: 'Task'; id: string } | null
    }>
  }
}

export type FetchActivityFlowDataQueryVariables = Exact<{
  taskId: Scalars['ID']['input']
}>

export type FetchActivityFlowDataQuery = {
  __typename?: 'Query'
  getTaskActivityFlowData: Array<{
    __typename?: 'ActivityFlowDataNode'
    nodeId: number
    parentId?: number | null
    activityCount: number
    summary: string
    adjacentNodes: Array<{
      __typename?: 'AdjacentActivityFlowDataNode'
      nodeId: number
      traceCount: number
    }>
  }>
}

export type TestTasksQueryVariables = Exact<{ [key: string]: never }>

export type TestTasksQuery = {
  __typename?: 'Query'
  tasks: Array<{ __typename?: 'Task'; id: string }>
}

export const ApplyTaskRefinementDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'applyTaskRefinement' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'refinementId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applyTaskRefinement' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'refinementId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplyTaskRefinementMutation,
  ApplyTaskRefinementMutationVariables
>
export const CreateMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateMessageInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'body' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMessageMutation,
  CreateMessageMutationVariables
>
export const CreateTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateTaskInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taskInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateTaskMutation, CreateTaskMutationVariables>
export const DeleteTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taskId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTaskMutation, DeleteTaskMutationVariables>
export const DeleteNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteNotifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteNotifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteNotificationsMutation,
  DeleteNotificationsMutationVariables
>
export const FetchAllUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchAllUsers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchAllUsersQuery, FetchAllUsersQueryVariables>
export const FetchAllTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchAllTasks' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tasks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchAllTasksQuery, FetchAllTasksQueryVariables>
export const SimilarTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SimilarTasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'minSimilarityScore' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'similarTasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taskId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taskId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'minSimilarityScore' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'minSimilarityScore' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SimilarTasksQuery, SimilarTasksQueryVariables>
export const TaskMessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TaskMessages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'taskId' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thread' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'author' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'avatarUrl' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'body' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'task' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TaskMessagesQuery, TaskMessagesQueryVariables>
export const FetchTasksByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchTasksById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taskIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'assigneeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thread' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'author' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'avatarUrl' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'body' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subtasks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentTask' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentTask' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchTasksByIdQuery, FetchTasksByIdQueryVariables>
export const OrderSubtasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderSubtasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderedSubtaskIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'insertAfterSubtaskWithId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderSubtasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'orderedSubtaskIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderedSubtaskIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'insertAfterSubtaskWithId' },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'insertAfterSubtaskWithId',
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subtasks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OrderSubtasksMutation,
  OrderSubtasksMutationVariables
>
export const RefineTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'refineTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'precedentTaskIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'previousTaskRefinementFeedback' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'PreviousTaskRefinementFeedbackInput',
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refineTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'taskId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'taskId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'precedentTaskIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'precedentTaskIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'previousTaskRefinementFeedback',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'previousTaskRefinementFeedback',
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'change' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentTaskId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'precedentTasks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RefineTaskMutation, RefineTaskMutationVariables>
export const SetTaskStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetTaskStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TaskStatus' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setTaskStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetTaskStatusMutation,
  SetTaskStatusMutationVariables
>
export const UpdateTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'title' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'description' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assigneeId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueAt' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'parentTaskId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'description' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigneeId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'assigneeId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dueAt' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dueAt' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parentTaskId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'parentTaskId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'assigneeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateTaskMutation, UpdateTaskMutationVariables>
export const FetchViewerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchViewer' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchViewerQuery, FetchViewerQueryVariables>
export const FetchViewerWithTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchViewerWithTasks' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentTask' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchViewerWithTasksQuery,
  FetchViewerWithTasksQueryVariables
>
export const FetchViewerWithNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchViewerWithNotifications' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sourceMessage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'author' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'avatarUrl' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'body' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchViewerWithNotificationsQuery,
  FetchViewerWithNotificationsQueryVariables
>
export const FetchActivityFlowDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchActivityFlowData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTaskActivityFlowData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taskId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taskId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'nodeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'adjacentNodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodeId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'traceCount' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityCount' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchActivityFlowDataQuery,
  FetchActivityFlowDataQueryVariables
>
export const TestTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TestTasks' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tasks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TestTasksQuery, TestTasksQueryVariables>
